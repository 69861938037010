import React, {
  SyntheticEvent,
  useEffect, useRef, useState,
} from 'react';
import {
  Box,
} from '@mui/material';
import { useLocation, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Download as DownloadIcon } from '@mui/icons-material';
import { format } from 'rut.js';
import Page from '../../../components/Layout/Page';
import Header from '../../../components/Layout/Header';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import CustomTable from '../../../components/General/CustomTable';
import EmptyTable from '../../../components/General/EmptyTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import {
  getSchools, getSessionTestsGraphInformation, getStatisticsInExcel, getTestResultsPerPage,
} from '../../../requests/api/institutionTests';
import { Histogram } from './Component/Histogram';
import CustomButton from '../../../components/General/CustomButton';
import { InstitutionSessionUserTest, SessionTestResult } from './types';

const INSTITUTIONS_SESSION_RESULTS_UI = [{
  label: 'RUT', key: 'rut', valueType: 'string',
}, {
  label: 'Nombre', key: 'name', valueType: 'string',
},
{
  label: 'Colegio', key: 'school', valueType: 'string',
},
{
  label: 'Puntaje PAES', key: 'score', valueType: 'string',
},
{
  label: 'Correctas', key: 'numberOfCorrectOnes', valueType: 'string',
},
];

interface LocationState {
  name: string,
}

const InstitutionSessionTestView = () => {
  const { institutionSessionTestId } = useParams<{
    institutionId: string, institutionSessionId: string, institutionSessionTestId: string,
  }>();
  const { state } = useLocation<LocationState>();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoading, setShowLoading] = useState(true);
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [allTestResults, setAllTestResults] = useState({
    totalItems: 0,
    items: [],
  });
  const [graphData, setGraphData] = useState<{
    data: number[],
    categories: string[],
  }>({
    data: [],
    categories: [],
  });
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolFilter, setSchoolFilter] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [showDownloadExcelLoading, setShowDownloadExcelLoading] = useState(false);
  const isMounted = useRef(true);

  const getSchoolsOptions = async () => {
    // id from schools is a dummy id from backend, there is not a unique school id
    const { relevantSchools } = await getSchools(institutionSessionTestId);
    setSchoolOptions(relevantSchools);
  };

  const getInitialGraphInformation = async () => {
    const { institutionSessionTestResult } = await getSessionTestsGraphInformation({
      filter: schoolFilter,
      institutionSessionTestId,
    }) as { institutionSessionTestResult: SessionTestResult };

    const entries = Object
      .entries(institutionSessionTestResult)
      .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));

    const sortedObj = Object.fromEntries(entries);

    setGraphData({
      categories: Object.keys(sortedObj),
      data: Object.values(sortedObj),
    });
  };

  const getTestResults = async () => {
    const { tests, totalTests, testScoresTable } = await getTestResultsPerPage({
      page: tableParameters.page,
      limit: tableParameters.rowsPerPage,
      searchValue,
      filter: schoolFilter,
      institutionSessionTestId,
    });

    const auxTableValues = tests.map((elem: InstitutionSessionUserTest) => {
      const numberOfCorrectOnes = elem.institutionSessionUserTestQuestions
        ?.reduce((acc, val) => (acc + (val.isCorrect ? 1 : 0)), 0);
      const score = testScoresTable.find((s: {
        correctAnswers: number,
      }) => s.correctAnswers === numberOfCorrectOnes);
      return {
        rut: format(elem.institutionSessionUser?.institutionUser.rut || ''),
        name: `${elem.institutionSessionUser?.institutionUser.name} ${elem.institutionSessionUser?.institutionUser.lastname}`,
        school: elem.institutionSessionUser?.institutionUser.school,
        score: score?.score,
        numberOfCorrectOnes,
      };
    });

    setAllTestResults({ totalItems: totalTests, items: auxTableValues });
  };

  useEffect(() => {
    const runInitialPageInfo = async () => {
      try {
        await Promise.all([getTestResults(), getInitialGraphInformation(), getSchoolsOptions()]);
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (isMounted.current) {
      runInitialPageInfo();
    }
  }, [isMounted]);

  useEffect(() => {
    const runUpdatedInformation = async () => {
      try {
        await Promise.all([getTestResults(), getInitialGraphInformation()]);
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (isMounted.current) {
      runUpdatedInformation();
    }
  }, [
    tableParameters.page,
    tableParameters.rowsPerPage,
    schoolFilter,
    searchValue,
    isMounted,
  ]);

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (tableParameters.page !== 0) {
      setTableParameters((prevState) => ({ ...prevState, page: 0 }));
    }
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value || (value as { id: string, name: string }).name === 'Todos') {
      setSchoolFilter('');
    } else {
      setSchoolFilter((value as { id: string, name: string }).name);
    }

    if (tableParameters.page !== 0) {
      setTableParameters((prevState) => ({ ...prevState, page: 0 }));
    }
  };

  const handleDownloadStatisticsExcel = async () => {
    try {
      setShowDownloadExcelLoading(true);
      const blob: Blob = await getStatisticsInExcel(
        institutionSessionTestId, schoolFilter, searchValue,
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Estadisticas alumnos.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      setShowDownloadExcelLoading(false);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, { message: 'Error al descargar archivo' }, false);
      setShowDownloadExcelLoading(false);
    }
  };

  return (
    <Page
      title="Instituciones | Resultados"
    >
      <Header
        title={`Estadística ${state?.name || 'Estadística'}`}
        goBack
        autocompletes={!showLoading ? [
          {
            name: 'Colegio',
            value: schoolOptions.find((elem: { name: string, id: string }) => elem.name === schoolFilter) || '',
            options: [{ name: 'Todos', id: '' }, ...schoolOptions],
            onChange: handleFilterChange,
            keyField: 'name',
            size: 'small',
          },
        ] : undefined}
        search={!showLoading ? { text: 'Buscar alumno...', value: searchValue, onChangeSearchValue: handleChangeSearchValue } : undefined}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Histogram graphData={allTestResults.totalItems === 0
            ? { data: [], categories: [] }
            : graphData} />
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'end', margin: '10px 0px' }}>
          <CustomButton loading={showDownloadExcelLoading} text='Descargar estadísticas' colorType='tertiary' onClick={() => handleDownloadStatisticsExcel()} key={'DownloadStatistics'} icon={<DownloadIcon />} />
        </Box>
        <Box mt={4}>
          {allTestResults.totalItems !== 0
            && <CustomTable
              headers={INSTITUTIONS_SESSION_RESULTS_UI}
              data={{
                values: allTestResults.items,
                count: allTestResults.totalItems,
              }}
              tableParameters={tableParameters}
              setTableParameters={setTableParameters}
            />}
          {allTestResults.totalItems === 0 && <EmptyTable />}
        </Box>

      </Box>}
    </Page>
  );
};

export default InstitutionSessionTestView;
